 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="sm"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Price & Terms</h2>
        </div>

        <div slot="body">
            <form @submit.prevent="">
                <div class="flex flex__justify-between mb-5">
                    <modal-field class="w-full mr-3" label="Price">
                        <input
                            :value="priceAmount"
                            class="w-full"
                            type="number"
                            :class="{'is-invalid': submitter && $v.priceAmount.$invalid}"
                            @input="updatePriceAmount($event.target.value)"
                        />
                    </modal-field>

                    <modal-field class="w-full" label="Currency">
                        <input
                            :value="priceCurrency"
                            class="w-full"
                            type="text"
                            :class="{'is-invalid': submitter && $v.priceCurrency.$invalid}"
                            @input="updatePriceCurrency($event.target.value)"
                        />
                    </modal-field>
                </div>

                <div class="flex flex__justify-between mb-5">
                    <modal-field class="w-full" label="Incoterms">
                        <input 
                            v-model="combiterms"
                            class="w-full"
                            type="text"
                        />
                    </modal-field>
                </div>

                <modal-field label="Who pays" body-class="flex flex__column flex__align-start">
                    <label 
                        v-for="item in ['Sender', 'Reciever', 'Third party']"
                        :key="item"
                        class="flex items-center mt-4 cursor-pointer"
                    >
                        <input v-model="payer" :value="item" type="radio" class="mr-2">

                        {{ item }}
                    </label>
                </modal-field>
            </form>
        </div>
        
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full">
                <div class="flex">
                    <button class="px-10 btn-primary" @click="save">Save</button>
                    
                    <button class="px-8 ml-4 btn-grey-outline" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'PriceTermsModal',

    props: {
        value: {
            type: Object,
            default: () => { return {} },
        }
    },

    data() {
        return {
            priceAmount: this.value.price_data.amount,
            priceCurrency: this.value.price_data.currency,
            payer: this.value.payer,
            combiterms: this.value.combiterms,
            submitter: false,
            isPriceAmountUpdated: false,
            isPriceCurrencyUpdated: false,
        }
    },

    methods: {
        updatePriceAmount(value) {
            this.isPriceAmountUpdated = true;
            this.priceAmount = value;
        },
        updatePriceCurrency(value) {
            this.isPriceCurrencyUpdated = true;
            this.priceCurrency = value;
        },
        save() {
            this.submitter = true;

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('save:prices', {
                prices: {
                    amount: this.priceAmount,
                    currency: this.priceCurrency,
                },
                consignment: {
                    payer: this.payer,
                    combiterms: this.combiterms,
                },
            });

            this.$emit('hide');
        }
    },

    validations: {
        priceAmount: {
            required,
        },
        priceCurrency: {
            required,
        },
    }
}
</script>
