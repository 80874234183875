<template>
    <div class="sh-d">
        <transition name="fade-modal">
            <detail-shipment-item-modal v-if="detailModal && !consignmentHasFullAddress" @hide="detailModal = false" :name="detailModalName" :value="consignment" @save="saveModal" />
        </transition>

        <transition name="fade-modal">
            <detail-shipment-address-modal
                :isShow="detailModal && consignmentHasFullAddress"
                :name="detailModalName"
                :value="consignment"
                @hide="detailModal = false"
                @updated="fetchConsignment"
            />
        </transition>

        <transition name="fade-modal">
            <price-terms-modal
                v-if="priceAndTermsModal"
                @hide="priceAndTermsModal = false"
                :value="consignment"
                @save:prices="saveModalPrices"
                @save:waybill="saveModalWaybill"
            />
        </transition>

        <transition name="fade-modal">
            <waybill-modal
                v-if="waybillModal"
                @hide="waybillModal = false"
                :value="consignment"
                @save="saveModalWaybill"
            />
        </transition>

        <transition name="fade-modal">
            <references-modal-block
                v-if="referencesModal"
                :value="consignment"
                :searate-type="searateType"
                :active-searates="activeSearates"
                @hide="referencesModal = false"
                @save="saveModal"
            />
        </transition>

        <transition name="fade-modal">
            <set-time-line-modal v-if="setTimeHide" @setTimeHide="setTimeHide = false" :value="setTimeValue" :consignment="consignment" @save="saveModal" />
        </transition>

        <transition name="fade-modal">
            <carrier-modal v-if="carrierHide" :dictionary="dictionary" @hide="carrierHide = false" :value="consignment" @refreshTable="saveModal" />
        </transition>

        <transition name="fade-modal">
            <owner-modal v-if="ownerHide" :dictionary="dictionary" @hide="ownerHide = false" :value="consignment" @refreshTable="fetchConsignment" />
        </transition>

        <transition name="fade-modal">
            <cargo-modal v-if="cargoShow" @hide="cargoShow = false" :value="consignment" @refreshTable="fetchConsignment" />
        </transition>

        <add-event-modal v-if="user" :prop-event="currentEventEdit" :trigger="addEventTrigger" :shipment="consignment" :exception-details-list="exception_details" @created="fetchConsignment" @reset="resetEvent" />

        <emissions-details-dialog
            v-if="consignment && user"
            :id="selectedEmission"
            @hide="selectedEmission = null"
            :showGotoShipment="false"
            :prop-from="consignment.pickup_city"
            :prop-to="consignment.delivery_city"
            @loading="emissionLoading = $event"
            class="sh-d__emissions"
        ></emissions-details-dialog>

        <page-header
            :branding="! adminRoute"
            class="absolute z-20 w-full"
            :title="shipmentTitle"
        >
            <template slot="tools" v-if="!isShared">
                <div class="flex">
                    <button v-if="adminLevel" class="btn-primary text-white text-body mr-3" @click="setArchive">{{ !consignment.is_archived ? 'Archive' : 'Dearchive' }}</button>

                    <a class="btn-transparent flex flex__align-center" :href="`/shared/consignments/${uuid}`" target="_blank">
                        <svg-importer icon-name="icons/share" class="mr-2" />
                        <span class="text-body">
                            Generate a public link
                        </span>
                    </a>
                </div>
            </template>

            <template slot="info">
                <div v-if="false">
                    <div class="sh-d__info ml-4 background-green">
                        <fa :icon="['fal', 'check-circle']" />
                        <span>ON TIME</span>
                    </div>
                </div>
            </template>
        </page-header>

        <div class="sh-d__body pb-4">
            <div class="sh-d__timeline mr-4">
                <div class="sh-d__h-100 flex flex__column">
                    <div class="sh-d__card flex__grow flex flex__column">
                        <div v-if="false && lastXception" class="sh-d__head flex flex__align-center flex__justify-between">
                            <div class="flex flex__align-center">
                                <button class="icon-btn icon-btn-blue cursor-default">
                                    <fa :icon="['fal','truck']" />
                                </button>

                                <span
                                    class="f-size-14 ml-2 uppercase break-words"
                                    style="max-width: 180px"
                                    :class="{
                                        'color-pink': lastXception.status === 'NOT_APPLIED' || lastXception.status === 'APPLIED',
                                        'color-green': lastXception.status  === 'RESOLVED',
                                        'color-yellow': lastXception.status  === 'UNDER_INVESTIGATION',
                                    }"
                                >Exception: {{ prepareExceptionDetails(lastXception.exception_details) }}</span>
                            </div>

                            <div
                                class="f-size-14 no-wrap"
                                :class="{
                                    'color-pink': lastXception.status === 'NOT_APPLIED' || lastXception.status === 'APPLIED',
                                    'color-green': lastXception.status  === 'RESOLVED',
                                    'color-yellow': lastXception.status  === 'UNDER_INVESTIGATION',
                                }"
                            >{{ prepareFullDate(lastXception.created_at) }}</div>
                        </div>

                        <div v-if="isTimelineFilled" class="sh-d__progress">
                            <div class="sh-d-progress">
                                <template v-for="(item, i) in timeLine">
                                    <shipment-progress-item
                                        v-if="!groupContainsOngoingInvestigation(item)"
                                        :key="i"
                                        :value="item"
                                        :permissions="permissions"
                                        :status-names="statusNames"
                                        :exception_details="exception_details"
                                        :exception_at="exception_at"
                                        @save="saveModal"
                                        @click="openEditEventModal"
                                    />
                                </template>
                                <div v-if="isDeliveredPointVisible" class="sh-d-progress__item">
                                    <div class="flex flex__grow relative pl-8">
                                        <div class="sh-d-circle">
                                            <div class="sh-d-circle__top"></div>
                                            <div class="sh-d-circle__circle sh-d-circle--empty"><span></span></div>
                                            <div class="sh-d-circle__line sh-d-circle--empty sh-d-circle__line--min"></div>
                                            <div class="sh-d-circle__line sh-d-circle--empty"></div>
                                        </div>

                                        <div class="w__100-p">
                                            <div class="sh-d-progress__content">
                                                <div class="sh-d-progress__head z-10 relative">
                                                    <div>
                                                        <div class="sh-d-progress__action f-w-bold f-size-18 flex flex__align-center">
                                                            {{ statusNames.DELIVERED }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <template v-for="(item, i) in timeLine">
                                    <shipment-progress-item
                                        v-if="groupContainsOngoingInvestigation(item)"
                                        :key="i"
                                        :value="item"
                                        :permissions="permissions"
                                        :status-names="statusNames"
                                        :exception_details="exception_details"
                                        :exception_at="exception_at"
                                        @save="saveModal"
                                        @click="openEditEventModal"
                                    />
                                </template>
                            </div>
                        </div>

                        <empty-timeline v-if="!isTimelineFilled" :status-names="statusNames"></empty-timeline>

                        <div v-if="adminLevel && !isShared" class="flex flex__justify-end pr-6 pb-4 mt-4">
                            <button class="btn-transparent text-body" @click="$modal.show('add-event-modal')">
                                Add event
                            </button>
                        </div>
                    </div>

                    <chat ref="chatComponent" class="sh-d__card px-4 mt-4" />
                </div>
            </div>

            <div class="sh-d__section flex flex__column">
                <div class="sh-d__card sh-d__grid flex px-4 py-4">
                    <div v-text="`From`" class="sh-d__from sh-d__grid-header sh-d__grid-header--blue text-center text-header-primary border-radius--input" />

                    <div class="sh-d__section-item sh-d__pickup sh-d__border">
                        <div class="sh-d__head flex flex__align-center flex__justify-between">
                            <div class="flex flex__align-center">
                                <icon name="icons/pick-up" inactive class="stroke-blue-1 fill-blue background-blue" />

                                <span class="f-size-16 ml-2">Pickup</span>

                                <button type="button" class="border-none bg-transparent" @click="openDetailModal('Pickup')">
                                    <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                </button>
                            </div>
                        </div>

                        <div class="sh-d__section-values">
                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Company</div>
                                <div class="text-subheader mt-1 word-break">
                                    {{ consignment.pickup_name || '-' }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Country</div>
                                <div class="text-subheader mt-1 word-break">
                                    {{ getFullAddressField({ row: consignment, key: 'pickup_countrycode' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">City</div>
                                <div class="text-subheader mt-1 word-break">
                                    {{ getFullAddressField({ row: consignment, key: 'pickup_city' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Address</div>
                                <div class="text-subheader mt-1 word-break">
                                    {{ getFullAddressField({ row: consignment, key: 'pickup_address' }) || '–' }}
                                </div>
                                <div v-if="getFullAddressField({ row: consignment, key: 'pickup_address2' })" class="text-subheader mt-1 word-break">
                                    {{ getFullAddressField({ row: consignment, key: 'pickup_address2' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Postcode</div>
                                <div class="text-subheader mt-1 word-break">
                                    {{ getFullAddressField({ row: consignment, key: 'pickup_postcode' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Contact</div>
                                <div class="text-subheader mt-1">
                                    <div class="text-subheader word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'pickup_contactperson' }) }}
                                    </div>

                                    <div v-if="consignment.pickup_email">
                                        {{ getFullAddressField({ row: consignment, key: 'pickup_email' }) }}
                                        <!-- <div v-for="(item, i) in consignment.pickup_email.split(',')" :key="i" :title="item" @click="copyText(item)" class="text-ellipsis cursor-pointer text-subheader">
                                            {{ item }}
                                        </div> -->
                                    </div>

                                    <div v-else class="text-subheader">–</div>

                                    <div class="text-subheader word-break">{{ getFullAddressField({ row: consignment, key: 'pickup_phone' }) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade">
                        <div v-if="isShowDetailed" class="sh-d__section-item sh-d__sender sh-d__border">
                            <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                                <div class="flex flex__align-center">
                                    <icon name="icons/sender" inactive class="stroke-blue-1 background-blue" />

                                    <span class="f-size-16 ml-2">Sender</span>

                                    <button type="button" class="border-none bg-transparent" @click="openDetailModal('Sender')">
                                        <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                    </button>
                                </div>
                            </div>

                            <div class="sh-d__section-values">
                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Company</div>
                                    <div class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_name' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Country</div>
                                    <div class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_countrycode' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">City</div>
                                    <div class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_city' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Address</div>
                                    <div class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_address' }) }}
                                    </div>
                                    <div v-if="getFullAddressField({ row: consignment, key: 'consignor_address2' })" class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_address2' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Postcode</div>
                                    <div class="text-subheader mt-1 word-break">
                                        {{ getFullAddressField({ row: consignment, key: 'consignor_postcode' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Contact</div>
                                    <div class="text-subheader mt-1">
                                        <div class="text-subheader word-break">{{consignment.consignor_contactperson || '–'}}</div>

                                        <div v-if="consignment.consignor_email">
                                            <div v-for="(item, i) in consignment.consignor_email.split(',')" :key="i" :title="item" @click="copyText(item)" class="text-ellipsis cursor-pointer text-subheader">
                                                {{ item }}
                                            </div>
                                        </div>

                                        <div v-else class="text-subheader">–</div>

                                        <div class="text-subheader word-break">{{ getFullAddressField({ row: consignment, key: 'consignor_phone' })}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="sh-d__section-item sh-d__timeline-1 sh-d__border">
                        <div class="sh-d__head pl-0">
                            <div class="flex flex__align-center">
                                <icon name="icons/timeline" inactive class="stroke-blue-1 background-blue fill-blue-second" />

                                <span class="f-size-16 ml-2">Timeline</span>

                                <button type="button" class="border-none bg-transparent" @click="setTime(timeActionTypeDict.sender)">
                                    <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                </button>
                            </div>
                        </div>

                        <div class="sh-d__section-values">
                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Requested pickup</div>
                                <div class="text-subheader mt-1">{{ prepareDate(consignment.pick_up_starts_at) || '–' }}</div>
                                <div v-if="isShowTime(consignment.pick_up_starts_at, consignment.pick_up_ends_at).start" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.pick_up_starts_at) || '–' }}</div>
                                <div v-if="isShowTime(consignment.pick_up_starts_at, consignment.pick_up_ends_at).end" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.pick_up_ends_at) }}</div>
                            </div>

                            <div class="sh-d__section-item mb-4 pr-3">
                                <div class="text-body color-text-secondary">Actual pickup</div>
                                <div v-text="prepareDate(consignment.actual_time_pick_up_at)" class="text-subheader mt-1" />
                            </div>

                            <div class="sh-d__section-item mb-4 pr-3">
                                <div class="text-body color-text-secondary">Estimated pickup</div>
                                <div v-text="prepareDate(consignment.estimated_pickup_starts_at)" class="text-subheader mt-1" />
                                <div v-if="isShowTime(consignment.estimated_pickup_starts_at, consignment.estimated_pickup_ends_at).start" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.estimated_pickup_starts_at) || '–' }}</div>
                                <div v-if="isShowTime(consignment.estimated_pickup_starts_at, consignment.estimated_pickup_ends_at).end" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.estimated_pickup_ends_at) }}</div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Estimated Departure Date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.estimated_time_departure_at) }}
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Actual departure date</div>
                                    <div class="text-subheader mt-1">{{prepareDate(consignment.actual_time_departure_at)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade">
                        <div v-if="isShowDetailed" class="sh-d__section-item sh-d__bottom-1 sh-d__border">
                            <div class="sh-d__section-values flex__column">
                                <div class="py-4 sh-d__border">
                                    <div class="text-body color-text-secondary">Pickup instruction</div>
                                    <div class="text-subheader mt-1">{{ consignment.pick_up_instructions || '–' }}</div>
                                </div>

                                <div class="py-4">
                                    <div class="text-body color-text-secondary">Goods description</div>
                                    <div class="text-subheader mt-1">{{ consignment.goods_description || '–' }}</div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div v-text="`To`" class="sh-d__to sh-d__grid-header sh-d__grid-header--green text-center text-header-primary border-radius--input" />

                    <div class="sh-d__section-item sh-d__delivery sh-d__border">
                        <div class="sh-d__head">
                            <div class="flex flex__align-center">
                                <icon name="icons/delivery" inactive class="stroke-green fill-green background-green" />

                                <span class="f-size-16 ml-2">Delivery</span>

                                <button type="button" class="border-none bg-transparent" @click="openDetailModal('Delivery')">
                                    <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                </button>
                            </div>
                        </div>

                        <div class="sh-d__section-values">
                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Company</div>
                                <div class="text-subheader mt-1">
                                    {{ consignment.delivery_name || '-' }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Country</div>
                                <div class="text-subheader mt-1">
                                    {{ getFullAddressField({ row: consignment, key: 'delivery_countrycode' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">City</div>
                                <div class="text-subheader mt-1">
                                    {{ getFullAddressField({ row: consignment, key: 'delivery_city' }) || '–' }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Address</div>
                                <div class="text-subheader mt-1">
                                    {{ getFullAddressField({ row: consignment, key: 'delivery_address' }) || '–' }}
                                </div>
                                <div v-if="getFullAddressField({ row: consignment, key: 'delivery_address2' })" class="text-subheader mt-1">
                                    {{ getFullAddressField({ row: consignment, key: 'delivery_address2' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Postcode</div>
                                <div class="text-subheader mt-1">
                                    {{ getFullAddressField({ row: consignment, key: 'delivery_postcode' }) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Contact</div>
                                <div class="f-size-14 leading-tight mt-1 lh-28">
                                    <div class="text-subheader">{{consignment.delivery_contactperson || '–'}}</div>

                                    <div v-if="consignment.delivery_email">
                                        <div v-for="(item, i) in consignment.delivery_email.split(',')" :key="i" :title="item" @click="copyText(item)" class="text-ellipsis cursor-pointer text-subheader">
                                            {{ item }}
                                        </div>
                                    </div>

                                    <div v-else class="text-subheader">–</div>

                                    <div class="text-subheader">{{ getFullAddressField({ row: consignment, key: 'delivery_phone' }) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade">
                        <div v-if="isShowDetailed" class="sh-d__section-item sh-d__receiver sh-d__border">
                            <div class="sh-d__head pl-0">
                                <div class="flex flex__align-center">
                                    <icon name="icons/receiver" inactive class="stroke-green fill-green background-green" />

                                    <span class="f-size-16 ml-2">Receiver</span>

                                    <button type="button" class="border-none bg-transparent" @click="openDetailModal('Receiver')">
                                        <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                    </button>
                                </div>
                            </div>

                            <div class="sh-d__section-values">
                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Company</div>
                                    <div class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_name' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Country</div>
                                    <div class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_countrycode' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">City</div>
                                    <div class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_city' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Address</div>
                                    <div class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_address' }) }}
                                    </div>
                                    <div v-if="getFullAddressField({ row: consignment, key: 'consignee_address2' })" class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_address2' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Postcode</div>
                                    <div class="text-subheader mt-1">
                                        {{ getFullAddressField({ row: consignment, key: 'consignee_postcode' }) }}
                                    </div>
                                </div>

                                <div class="sh-d__section-item pr-3 mb-4">
                                    <div class="text-body color-text-secondary">Contact</div>
                                    <div class="text-subheader mt-1">
                                        <div class="text-subheader">{{consignment.consignee_contactperson || '–'}}</div>

                                        <div v-if="consignment.consignee_email">
                                            <div v-for="(item, i) in consignment.consignee_email.split(',')" :key="i" :title="item" @click="copyText(item)" class="text-ellipsis cursor-pointer text-subheader">
                                                {{ item }}
                                            </div>
                                        </div>

                                        <div v-else class="text-subheader">–</div>

                                        <div class="text-subheader">{{ getFullAddressField({ row: consignment, key: 'consignee_phone' }) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="sh-d__section-item sh-d__timeline-2 sh-d__border">
                        <div class="sh-d__head flex flex__align-center flex__justify-between pl-0">
                            <div class="flex flex__align-center">
                                <icon name="icons/timeline" inactive class="stroke-green fill-green-second background-green" />

                                <span class="f-size-16 ml-2">Timeline</span>

                                <button type="button" class="border-none bg-transparent" @click="setTime(timeActionTypeDict.receiver)">
                                    <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="ml-2 cursor-pointer" />
                                </button>
                            </div>
                        </div>

                        <div class="sh-d__section-values">
                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Estimated arrival date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.estimated_arrival_at) }}
                                    </div>
                                </div>

                                <div v-else>
                                    <div class="text-body color-text-secondary">Estimated Delivery Date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.estimated_delivery_ends_at || consignment.estimated_delivery_starts_at) }}
                                    </div>
                                    <div v-if="isShowTime(consignment.estimated_delivery_starts_at, consignment.estimated_delivery_ends_at).start" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.estimated_delivery_starts_at) || '–' }}</div>
                                    <div v-if="isShowTime(consignment.estimated_delivery_starts_at, consignment.estimated_delivery_ends_at).end" class="f-s-10 leading-tight mt-1">{{ prepareTime(consignment.estimated_delivery_ends_at) }}</div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Actual arrival date</div>
                                    <div class="text-subheader mt-1">{{ prepareDate(consignment.actual_time_arrival_at) }}</div>
                                </div>

                                <div v-else>
                                    <div class="text-body color-text-secondary">Actual delivery date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.actual_time_delivery_at) }}
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div class="text-body color-text-secondary">Initially Estimated Delivery Date</div>
                                <div class="text-subheader mt-1">
                                    {{ prepareDate(consignment.etadate) }}
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Estimated Delivery Date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.estimated_delivery_ends_at || consignment.estimated_delivery_starts_at) }}
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d__section-item pr-3 mb-4">
                                <div v-if="isOceanOrAir">
                                    <div class="text-body color-text-secondary">Actual delivery date</div>
                                    <div class="text-subheader mt-1">
                                        {{ prepareDate(consignment.actual_time_delivery_at) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <transition name="fade">
                        <div v-if="isShowDetailed" class="sh-d__section-item sh-d__bottom-2 sh-d__border">
                            <div class="sh-d__section-values">
                                <div class="py-4">
                                    <div class="text-body color-text-secondary">Delivery instruction</div>
                                    <div class="text-subheader mt-1">{{ consignment.delivery_instructions || '–' }}</div>
                                </div>
                            </div>
                        </div>
                    </transition>

                    <div class="sh-d__toggle mt-4">
                        <button class="flex flex__align-center" @click="isShowDetailed = !isShowDetailed">
                            <span v-text="getShowDetailedText()" class="text-subheader color-brand" />

                            <svg-importer icon-name="icons/arrow" class="ml-4 stroke-brand" :class="{ 'rotate-180': isShowDetailed }" />
                        </button>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="sh-d__card sh-d__h-100">
                        <div class="sh-d-additional">
                            <div class="sh-d-additional__item">
                                <div class="flex sh-d-additional__head mb-3">
                                    <svg-importer icon-name="icons/user" class="mr-1" />

                                    <span class="text-header-secondary mr-2">Owner</span>

                                    <div>
                                        <button type="button" class="border-none bg-transparent" @click="ownerHide = true">
                                            <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                <div class="sh-d-additional__list">
                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Organization</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words text-ellipsis" :title="consignment.owner_from_source || ''">{{ consignment.owner_from_source || '–' }}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Site</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.owner_site_from_source || '–'}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d-additional__item">
                                <div class="flex sh-d-additional__head mb-3">
                                    <svg-importer icon-name="icons/truck" class="mr-2" />

                                    <span class="text-header-secondary mr-1">Carrier</span>

                                    <div>
                                        <button type="button" class="border-none bg-transparent" @click="carrierHide = true">
                                            <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                <div class="sh-d-additional__list">
                                    <div class=" sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-1 sh-detail__right-subtitle">Carrier</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">
                                            {{ carrierName }}
                                        </div>
                                    </div>

                                    <div class=" sh-d-additional__list-item mb-2">
                                        <div style="display: flex; align-items: center;">
                                            <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle" style="min-width: auto;">Service</div>
                                            <svg-importer v-if="consignment.is_green" style="width: 18px" icon-name="icons/feather" />
                                        </div>

                                        <div v-if="isShared" class="text-subheader w__100-p w__min-50-p break-words">{{ carrierServiceName }}</div>

                                        <div v-else-if="!adminLevel" class="text-subheader w__100-p w__min-50-p break-words">{{ carrierServiceName }}</div>

                                        <a v-else class="text-subheader w__100-p w__min-50-p break-words color-brand" :href="`/admin/carriers/${carrierId}?carrier_service_id=${carrierServiceId}`" target="_blank">{{ carrierServiceName }}</a>
                                    </div>


                                    <div class=" sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Mode</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.transport_mode || '–' }}</div>
                                    </div>

                                    <div
                                        v-if="consignment.transport_mode === 'ROAD' || consignment.transport_mode === 'MULTIMODAL' || consignment.transport_mode === 'EXPRESS'"
                                        class="sh-d-additional__list-item mb-2"
                                    >
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Plate no</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.plate_no || '–' }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d-additional__item">
                                <div class="flex sh-d-additional__head mb-3" :class="{ clickable: !adminLevel && !isShared }" @click="!adminLevel && !isShared ? cargoShow=true : false">
                                    <svg-importer icon-name="icons/box" class="mr-2" />

                                    <span class="text-header-secondary mr-1">Cargo info</span>

                                    <div v-if="adminLevel && !isShared">
                                        <button type="button" class="border-none bg-transparent" @click="cargoShow = true">
                                            <svg-importer icon-name="icons/edit" class="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                <div class="sh-d-additional__list">
                                    <div v-if="consignment.dimensions_items_count !== undefined" class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Items total</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">
                                            <span v-if="consignment.dimensions_items_count">
                                                {{ consignment.dimensions_items_count }}
                                            </span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Volume</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">
                                            <span v-if="roundNumber(consignment.dimensions_volume)">
                                                {{ roundNumber(consignment.dimensions_volume) || '–' }} {{ consignment.dimensions_volume_unit }}
                                            </span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Weight</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">
                                            <span v-if="roundNumber(consignment.dimensions_weight)">
                                                {{ roundNumber(consignment.dimensions_weight) || '–' }} {{ consignment.dimensions_weight_unit }}
                                            </span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Loading meters</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ roundNumber(consignment.dimensions_loadingmetres) || '–' }}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Type</div>
                                        <div v-if="!adminLevel && isDisplayGoodType" class="text-subheader w__100-p w__min-50-p break-words">
                                            {{ packageTypeTotal.length ? packageTypeTotal.map(item => item.name || item.code).join(', ') : '–' }}
                                        </div>

                                        <div v-else-if="adminLevel && isDisplayGoodType">
                                            <template v-if="packageTypeTotal.length">
                                                <a
                                                    v-for="(item, index) in packageTypeTotal"
                                                    :key="`good-type-dictionary_${index}`"
                                                    :href="`/admin/good-type-dictionary?id=${item.id}`"
                                                    target="_blank"
                                                    class="text-subheader w__100-p w__min-50-p break-words"
                                                >
                                                    <span>
                                                        {{ item.name || item.code }}
                                                    </span>
                                                    <span v-if="packageTypeTotal.length > 1 && index < packageTypeTotal.length - 1">,</span>
                                                </a>
                                            </template>
                                            <template v-else>
                                                -
                                            </template>
                                        </div>

                                        <div v-else class="text-subheader w__100-p w__min-50-p break-words">–</div>
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d-additional__item">
                                <div class="flex sh-d-additional__head mb-3">
                                    <svg-importer icon-name="icons/price-n-terms" class="mr-2" />

                                    <span class="text-header-secondary mr-1">Price & Terms</span>

                                    <div>
                                        <button type="button" class="border-none bg-transparent" @click="priceAndTermsModal = true">
                                            <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                <div class="sh-d-additional__list">
                                    <div v-if="!isShared" class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Price</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">
                                            {{ consignment.price_data && Object.values(consignment.price_data).find(val => val) ? roundNumber(consignment.price_data.amount) : '–' }}
                                            <span class="ml-1">
                                                {{ consignment.price_data.currency || '' }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Who pays</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.payer || '–' }}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Incoterms</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.combiterms || '–' }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d-additional__item">
                                <div class="flex sh-d-additional__head mb-3">
                                    <svg-importer icon-name="icons/reference" class="mr-2" />

                                    <span class="text-header-secondary mr-1">Reference</span>

                                    <div>
                                        <button type="button" class="border-none bg-transparent" @click="referencesModal = true">
                                            <svg-importer v-if="adminLevel && !isShared" icon-name="icons/edit" class="cursor-pointer" />
                                        </button>
                                    </div>
                                </div>

                                <div class="sh-d-additional__list">
                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Senders ref.</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{consignment.order_no || '–'}}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Recipients ref.</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{consignment.consignee_reference || '–'}}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Template</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{consignment.template || '–'}}</div>
                                    </div>

                                    <div class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Booking no</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{consignment.booking_number || '–'}}</div>
                                    </div>

                                    <div v-if="adminLevel && !isShared" class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Agreement</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ consignment.consignor_agreement_name || consignment.agreement_no || '-' }}</div>
                                    </div>

                                    <div v-if="adminLevel && !isShared" class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">User name</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{consignment.user_name || '–'}}</div>
                                    </div>

                                    <div v-if="isShippingOcean" class="sh-d-additional__list-item mb-2">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mt-1 mr-2 sh-detail__right-subtitle">Ocean tracking No</div>

                                        <div class="text-subheader w__100-p w__min-50-p break-words">{{ oceanTrackingNo }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="sh-d-additional__item flex flex__column border-none">
                                <div class="flex flex__align-center sh-d-additional__head mb-18px">
                                    <span class="text-header-secondary mr-2">Attached documents  <fa v-if="loadingUpload" class="fa-spin brand-primary ml-2" :icon="['fad','spinner-third']" /></span>
                                </div>


                                <div class="sh-d-additional__list-item mb-3">
                                    <div class="flex flex__justify-between">
                                        <div class="text-body color-text-secondary w__100-p w__min-50-p mr-2 sh-detail__right-subtitle">Waybill</div>
                                        <div>
                                            <button v-if="adminLevel && !isShared" type="button" class="border-none bg-transparent" @click="waybillModal = true">
                                                <svg-importer icon-name="icons/edit" class="cursor-pointer" />
                                            </button>
                                        </div>
                                    </div>

                                    <div v-if="consignment.customs_declaration" class="text-subheader w__100-p w__min-50-p break-words">
                                        <component
                                            :is="consignment.customs_declaration && consignment.customs_declaration.id ? 'a' : 'span'"
                                            :href="`/customs/${consignment.customs_declaration.id}`"
                                            target="_blank"
                                        >
                                            {{ consignment.waybill_number }}
                                        </component>
                                    </div>

                                    <div v-else>–</div>
                                </div>

                                <div>
                                    <div v-for="(item, i) in consignment.documents" :key="i" class="mb-3">
                                        <div class="doc-icon flex flex__align-center flex__justify-between color-blue f-size-14">
                                            <div class="flex flex__align-center">
                                                <svg class="mr-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.0417 6.09359V1.6665H4.53125C4.09831 1.6665 3.75 2.01481 3.75 2.44775V17.5519C3.75 17.9849 4.09831 18.3332 4.53125 18.3332H15.4688C15.9017 18.3332 16.25 17.9849 16.25 17.5519V6.87484H11.8229C11.3932 6.87484 11.0417 6.52327 11.0417 6.09359ZM13.5303 12.9738L10.3916 16.089C10.1751 16.3042 9.82552 16.3042 9.60905 16.089L6.47038 12.9738C6.13997 12.646 6.37174 12.0832 6.83659 12.0832H8.95833V9.479C8.95833 9.19124 9.19141 8.95817 9.47917 8.95817H10.5208C10.8086 8.95817 11.0417 9.19124 11.0417 9.479V12.0832H13.1634C13.6283 12.0832 13.86 12.646 13.5303 12.9738ZM16.0221 5.08447L12.8353 1.89437C12.6888 1.74788 12.4902 1.6665 12.2819 1.6665H12.0833V5.83317H16.25V5.6346C16.25 5.42953 16.1686 5.23096 16.0221 5.08447Z" fill="#00A3FF"/>
                                                </svg>

                                                <div target="_blank" class="break-words">
                                                    {{ item.filename }}
                                                </div>
                                            </div>

                                            <div class="flex ml-2">
                                                <fa :icon="['fal', 'arrow-to-bottom']" @click="download(item)" />
                                                <fa :icon="['fal', 'trash-alt']" @click="openDeleteDocumentModal(item)" class="ml-1" />
                                            </div>
                                        </div>

                                        <div class="text-body color-text-secondary">{{ prepareFullDate(item.created_at) }}</div>
                                    </div>
                                </div>

                                <file-input v-if="!isShared" multiple  @update="addDocument" class="mb-2" />

                                <div v-show="consignment.documents.length > 1" class="f-size-14 color-blue cursor-pointer mb-2 mt-2" @click="downloadAll">
                                    Download All
                                    <fa :icon="['fal', 'arrow-to-bottom']" class="ml-2" />
                                </div>
                            </div>

                            <a :href="linkDoc" :download="nameDoc" ref="linkDoc"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <shipment-document-delete-modal
            :show="isShowDocumentDeleteModal"
            :file="documentToDelete"
            :loading="loadingUpload"
            @delete="deleteDocument(documentToDelete)"
            @hide="closeDeleteDocumentModal"
        />
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import ShipmentProgressItem from '~/components/ShipmentProgress/Item.vue';
import EmptyTimeline from '~/components/ShipmentProgress/EmptyTimeline.vue';
import DetailShipmentItemModal from '~/pages/transport/modals/DetailShipmentItemModal';
import PriceTermsModal from '~/pages/transport/modals/PriceTermsModal';
import ReferencesModalBlock from '~/pages/transport/modals/ReferencesModal';
import CarrierModal from '~/pages/transport/modals/CarrierModal';
import CargoModal from '~/pages/transport/modals/CargoModal';
import OwnerModal from '~/pages/transport/modals/OwnerModal';
import WaybillModal from '~/pages/transport/modals/WaybillModal';
import FileInput from '~/components/FileInput';
import SetTimeLineModal from '~/pages/transport/modals/SetTimeLineModal'
import EmissionsDetailsDialog from '~/pages/transport/emissions/EmissionsDetailsDialog';
import AddEventModal from '~/pages/transport/modals/AddEventModal';
import Chat from './Chat.vue';
import DownloadMixin from './download.mixin';
import ShipmentAddressMixin from './shipments-address.mixin';
import DetailShipmentAddressModal from '~/pages/transport/modals/DetailShipmentAddressModal.vue';
import ShipmentDocumentDeleteModal from './modals/ShipmentDocumentDelete.vue';

import { roundNumber } from '@/utils/roundNumber.js';

const formatTwoDigits = (value) => {
    return String(value).length < 2 ? `0${value}` : value;
};

const allEventsSorter = (a, b) => {
    const aSortableField = getSortableField(a);
    const bSortableField = getSortableField(b);

    if (!aSortableField || !bSortableField) {
        return 0;
    }

    if (a[aSortableField] < b[bSortableField]) {
        return -1;
    }

    if (a[aSortableField] > b[bSortableField]) {
        return 1;
    }

    return 0;
}

const getSortableField = (event) => {
    return 'time' in event ? 'time' : 'created_at';
};

const ESTIMATED_MANUAL_ONLY_LABEL = 'Only manual updates allowed';

export default {
    components: {
        ShipmentProgressItem,
        EmptyTimeline,
        DetailShipmentItemModal,
        PriceTermsModal,
        ReferencesModalBlock,
        FileInput,
        SetTimeLineModal,
        CarrierModal,
        CargoModal,
        OwnerModal,
        EmissionsDetailsDialog,
        AddEventModal,
        Chat,
        DetailShipmentAddressModal,
        ShipmentDocumentDeleteModal,
        WaybillModal,
    },

    name: "detail",

    mixins: [DownloadMixin, ShipmentAddressMixin],

    metaInfo() {
        return { title: "Shipment detail" };
    },

    data() {
        return {
            isShowDetailed: false,
            lastXception: null,
            detailModal: false,
            priceAndTermsModal: false,
            referencesModal: false,
            waybillModal: false,
            showShareDialog: false,
            carrierHide: false,
            carrierValue: {},
            consignment: { carrier_data: {}, documents: [], price_data: {} },
            detailModalName: '',
            timeLine: [],
            linkDoc: '',
            setTimeHide: false,
            setTimeValue: {
                key: '',
                values: []
            },
            dictionary: [],
            nameDoc: '',
            permissions: {},
            hasEmissionsEnabled: false,
            exception_details: [],
            exception_at: [],
            ownerHide: false,
            selectedEmission: null,
            emissionLoading: false,
            editGoodsDescription: false,
            goodsDescription: '',
            loadingGoodsDescription: false,
            cargoShow: false,
            emissions: {},
            statusNames: {
                PRE_TRANSPORT: 'Awaiting confirmation',
                DELIVERED: 'Delivered',
            },
            currentEventEdit: null,
            addEventTrigger: 0,
            goodTypeDictionary: [],
            timeActionTypeDict: {
                receiver: 'set_receiver',
                sender: 'set_sender'
            },
            isShowDocumentDeleteModal: false,
            documentToDelete: null,
            consignmentUrlQuery: "?include=events,exceptions,consignorFullAddress.contact,consigneeFullAddress.contact,pickupFullAddress.contact,deliveryFullAddress.contact,consignorFullAddress.city,consigneeFullAddress.city,pickupFullAddress.city,deliveryFullAddress.city",
        };
    },

    computed: {
        consignmentHasFullAddress() {
            return Boolean(this.consignment[`${this.addressModalKeyNameDictionary[this.detailModalName]}_full_address`]);
        },
        documents() {
            return this.consignment.documents;
        },
        packageTypeTotal() {
            return this.consignment?.goods_types;
        },
        isDisplayGoodType() {
            return Boolean(this.packageTypeTotal?.length);
        },
        shipmentTitle() {
            let title = `Shipment ${this.consignment?.consignment_no}`;
            if (this.consignment.status) {
                title += `: ${this.consignment.status}`
            }
            return title;
        },

        isShared() {
            return this.$route.name === 'shared.consignments.detail';
        },

        user() {
            return this.$store.getters['auth/user'];
        },

        adminRoute() {
            return this.$route.name === 'admin.shipments.detail';
        },

        adminLevel() {
            return this.$store.getters['auth/isGodUser'];
        },

        uuid() {
            return this.$route.params.uuid;
        },

        accessEmission() {
            if (this.adminLevel) {
                return true;
            }

            return this.user?.site?.applications?.find(item => { return item === 'emissions' });
        },

        typeTimeLine() {
            let result = '';

            const errors = this.timeLine.find(item => { return item.xception && item.xception.status !== 'RESOLVED' });
            const success = this.timeLine.find(item => { return item.xception && item.xception.status === 'RESOLVED' });

            if (errors) {
                return 'error';
            }

            if (success) {
                return 'success';
            }

            return result;
        },

        isTimelineFilled() {
            return this.timeLine.length > 0;
        },

        isDeliveredPointVisible() {
            return !this.hasDeliveredEvents;
        },

        hasDeliveredEvents() {
            for (let i = 0; i < this.consignment.events.length; ++i) {
                if (this.consignment.events[i].group === this.statusNames.DELIVERED) {
                    return true;
                }
            }
            return false;
        },
        carrierName() {
            return this.consignment?.carrier_name || this.consignment?.carrier_data?.carrier_name || '–';
        },
        carrierId() {
            return this.consignment?.carrier_id;
        },
        carrierServiceId() {
            return this.consignment?.carrier_service_id;
        },
        carrierServiceName() {
            return this.consignment?.carrier_service_name || this.consignment?.carrier_data?.carrier_service_name || '–';
        },
        isShippingOcean() {
            return this.consignment?.transport_mode === 'OCEAN';
        },
        activeSearates() {
            return this.isShippingOcean
                ? this.consignment?.searates?.filter(searate => searate.active)
                : [];
        },
        searateType() {
            return this.activeSearates[0]?.type;
        },
        oceanTrackingNo() {
            if (!this.activeSearates?.length) {
                return '–';
            }

            let result = '–';

            if (this.searateType === 'BL') {
                result = this.activeSearates[0]?.number;
            }

            if (this.searateType === 'CN') {
                result = this.activeSearates?.map(searate => searate.number).join(', ');
            }

            return result;
        },
        isOceanOrAir() {
            return this.consignment.transport_mode === 'OCEAN' || this.consignment.transport_mode === 'AIR';
        },
    },

    methods: {

        groupContainsOngoingInvestigation(group) {
            return group.events.find(event => event.status === 'ONGOING_INVESTIGATION');
        },
        getShowDetailedText() {
            const pre = this.isShowDetailed ? 'Hide' : 'View';
            return `${pre} detailed information`;
        },
        copyText(value) {
            try {
                navigator.clipboard.writeText(value);

                this.$snotify.success(`E-mail copied`);
            } catch(error) {
                this.$snotify.success(`Error copy e-mail`);
            }
        },
        roundNumber(value) {
            return roundNumber(value);
        },

        openEmissions() {
            this.$nextTick(() => {
                this.selectedEmission = this.consignment.uuid;
            });
        },

        prepareExceptionDetails(value) {
            if (!value) {
                return '–';
            }

            if (this.adminRoute) {
                return value;
            }

            if (value === 'NO_PROGRESS') {
                return 'Waiting for status update';
            }

            return value;
        },

        async setArchive() {
            if (this.consignment.is_archived) {
                await this.fetchDearchive();

                return
            }

            await this.fetchArchive();
        },

        async fetchArchive() {
            try {
                await axios.post(`${this.$apiUrl.consignments.base}/${this.uuid}/archive`);

                await this.fetchConsignment();
                this.$refs.chatComponent?.fetchConsignment();

                this.$snotify.success(`Success`);
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },

        async fetchDearchive() {
            try {
                await axios.post(`${this.$apiUrl.consignments.base}/${this.uuid}/dearchive`);

                await this.fetchConsignment();
                this.$refs.chatComponent?.fetchConsignment();

                this.$snotify.success(`Success`);
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            }
        },

        async setDictionary() {
            const { data: { data } } = await axios.get(this.$apiUrl.dictionary);

            this.dictionary = data;

            this.exception_details = data.exception_details.map(item => {
                return {
                    label: item.label,
                    id: item.value,
                    active: true,
                    single_only: true,
                };
            });

            // this.exception_at = data.exception_at.map(item => {
            //     return {
            //         label: item.label,
            //         id: item.value,
            //         active: true,
            //         single_only: true,
            //     };
            // });
        },
        resetEvent() {
            this.currentEventEdit = null;
        },
        async fetchConsignment() {
            const url = !this.isShared
                ? `${this.$apiUrl.consignments.base}/` + this.uuid + this.consignmentUrlQuery
                : `/api/shared/consignments/${this.uuid}`;


            const { data } = await axios.get(url);

            this.consignment = data.data;

            if (this.isShared) {
                if (!this.consignment?.carrier_data) {
                    this.consignment.carrier_data = {};
                }

                if (!this.consignment?.price_data) {
                    this.consignment.price_data = {};
                }

                if (!this.consignment?.documents) {
                    this.consignment.documents = []
                }
            }

            this.goodsDescription = this.consignment.goods_description;

            this.setTimeLine();
        },

        openEditEventModal(event) {
            this.currentEventEdit = event;
            this.addEventTrigger++;
            this.$modal.show('add-event-modal')
        },

        async saveModal(value) {
            try {
                await axios.patch(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}`, value);

                await this.fetchConsignment();

                this.$snotify.success(`Success`);
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },

        async saveModalWaybill(waybill) {
            try {
                await axios.patch(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}/waybill`, { waybill_number: waybill });

                this.fetchConsignment();

                this.$snotify.success(`Success`);
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },

        async saveModalPrices({ prices, consignment }) {
            if (!prices && !consignment) {
                return;
            }

            try {
                const responses = await Promise.allSettled([
                    axios.patch(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}/prices`, prices),
                    axios.patch(`${this.$apiUrl.consignments.base}/${this.consignment.uuid}`, consignment),
                ]);

                const erroredResponse = responses.find(response => response.value.status !== 200);

                if (erroredResponse) {
                    this.$snotify.error(erroredResponse.value.data.message || 'An error occured');
                } else {
                    this.$snotify.success('Success');
                }

                this.fetchConsignment();
            } catch(error) {
                this.$snotify.error(error.response.data.message);
            }
        },

        getTimeFields(fieldsList) {
            const entries = fieldsList
                .map(
                    field => (
                        [
                            field,
                            {
                                value: moment(this.consignment[field]).isValid() ? this.consignment[field] : null,
                                label: (field[0]?.toUpperCase() + field.substring(1)).replace(/_/g, ' '),
                            },
                        ]
                    ),
                );

            return Object.fromEntries(entries);
        },

        getHours(datetime) {
            if (!datetime) {
                return;
            }

            const date = new Date(datetime);

            return String(formatTwoDigits(date.getHours()));
        },
        getMinutes(datetime) {
            if (!datetime) {
                return;
            }

            const date = new Date(datetime);

            return String(formatTwoDigits(date.getMinutes()));
        },

        getTimeModalPayload(type) {
            if (type === this.timeActionTypeDict.sender) {
                return {
                    pick_up_starts_at: {
                        label: 'Requested pickup',
                        key: 'pick_up_starts_at',
                        value: this.consignment.pick_up_starts_at,
                        startTime: { key: 'pick_up_starts_at', value: { hour: this.getHours(this.consignment.pick_up_starts_at), minute: this.getMinutes(this.consignment.pick_up_starts_at) } },
                        endTime: { key: 'pick_up_ends_at', value: { hour: this.getHours(this.consignment.pick_up_ends_at), minute: this.getMinutes(this.consignment.pick_up_ends_at) } },
                        show: true,
                        // dependency: 'pick_up_ends_at',
                    },
                    actual_time_pick_up_at: {
                        label: 'Actual pickup',
                        key: 'actual_time_pick_up_at',
                        value: this.consignment.actual_time_pick_up_at,
                        startTime: { key: 'actual_time_pick_up_at', value: { hour: this.getHours(this.consignment.actual_time_pick_up_at), minute: this.getMinutes(this.consignment.actual_time_pick_up_at) } },
                        endTime: null,
                        show: true
                    },
                    estimated_pickup_starts_at: {
                        label: 'Estimated pickup',
                        key: 'estimated_pickup_starts_at',
                        value: this.consignment.estimated_pickup_starts_at,
                        startTime: { key: 'estimated_pickup_starts_at', value: { hour: this.getHours(this.consignment.estimated_pickup_starts_at), minute: this.getMinutes(this.consignment.estimated_pickup_starts_at) } },
                        endTime: { key: 'estimated_pickup_ends_at', value: { hour: this.getHours(this.consignment.estimated_pickup_ends_at), minute: this.getMinutes(this.consignment.estimated_pickup_ends_at) } },
                        show: true,
                    },
                    estimated_time_departure_at: {
                        label: 'Estimated Departure Date',
                        key: 'estimated_time_departure_at',
                        value: this.consignment.estimated_time_departure_at,
                        startTime: { key: 'estimated_time_departure_at', value: { hour: this.getHours(this.consignment.estimated_time_departure_at), minute: this.getMinutes(this.consignment.estimated_time_departure_at) } },
                        endTime: null,
                        show: this.isOceanOrAir,
                    },
                    actual_time_departure_at: {
                        label: 'Actual departure date',
                        key: 'actual_time_departure_at',
                        value: this.consignment.actual_time_departure_at,
                        startTime: { key: 'actual_time_departure_at', value: { hour: this.getHours(this.consignment.actual_time_departure_at), minute: this.getMinutes(this.consignment.actual_time_departure_at) } },
                        endTime: null,
                        show: this.isOceanOrAir,
                    },
                };
            }

            return {
                estimated_delivery_starts_at: {
                    label: 'Estimated delivery date',
                    key: 'estimated_delivery_starts_at',
                    value: this.consignment.estimated_delivery_starts_at,
                    startTime: { key: 'estimated_delivery_starts_at', value: { hour: this.getHours(this.consignment.estimated_delivery_starts_at), minute: this.getMinutes(this.consignment.estimated_delivery_starts_at) } },
                    endTime: { key: 'estimated_delivery_ends_at', value: { hour: this.getHours(this.consignment.estimated_delivery_ends_at), minute: this.getMinutes(this.consignment.estimated_delivery_ends_at) } },
                    show: true,
                    children: {
                        estimated_delivery_manual_only: {
                            label: ESTIMATED_MANUAL_ONLY_LABEL,
                            key: 'estimated_delivery_manual_only',
                            value: Boolean(this.consignment.estimated_delivery_manual_only),
                            show: true,
                            type: 'checkbox',
                        },
                    },
                },
                actual_time_delivery_at: {
                    label: 'Actual delivery date',
                    key: 'actual_time_delivery_at',
                    value: this.consignment.actual_time_delivery_at,
                    startTime: { key: 'actual_time_delivery_at', value: { hour: this.getHours(this.consignment.actual_time_delivery_at), minute: this.getMinutes(this.consignment.actual_time_delivery_at) } },
                    endTime: null,
                    show: true,
                },
                estimated_arrival_at: {
                    label: 'Estimated arrival date',
                    key: 'estimated_arrival_at',
                    value: this.consignment.estimated_arrival_at,
                    startTime: { key: 'estimated_arrival_at', value: { hour: this.getHours(this.consignment.estimated_arrival_at), minute: this.getMinutes(this.consignment.estimated_arrival_at) } },
                    endTime: null,
                    show: this.isOceanOrAir,
                    children: {
                        estimated_arrival_manual_only: {
                            label: ESTIMATED_MANUAL_ONLY_LABEL,
                            key: 'estimated_arrival_manual_only',
                            value: Boolean(this.consignment.estimated_arrival_manual_only),
                            show: false,
                            type: 'checkbox',
                        },
                    },
                },
                actual_time_arrival_at: {
                    label: 'Actual arrival date',
                    key: 'actual_time_arrival_at',
                    value: this.consignment.actual_time_arrival_at,
                    startTime: { key: 'actual_time_arrival_at', value: { hour: this.getHours(this.consignment.actual_time_arrival_at), minute: this.getMinutes(this.consignment.actual_time_arrival_at) } },
                    endTime: null,
                    show: this.isOceanOrAir,
                },
            };
        },

        //// pick_up_starts_at
        setTime(actionName) {
            if (actionName === this.timeActionTypeDict.sender) {
                this.setTimeValue.key = actionName;
                this.setTimeValue.values = this.getTimeModalPayload(actionName);
                this.setTimeHide = true;

                return;
            }

            if (actionName === this.timeActionTypeDict.receiver) {
                this.setTimeValue.key = actionName;
                this.setTimeValue.values = this.getTimeModalPayload(actionName);
                this.setTimeHide = true;
            }
        },

        openDetailModal(value) {
            this.detailModal = true,
            this.detailModalName = value;
        },

        treesNeeded(actual_footprint) {
            return actual_footprint ? actual_footprint / 21772.4338 : null;
        },

        commentScroll() {
            setTimeout(() => {
                if (document.querySelector('.sh-d-comment__content')) {
                    document.querySelector('.sh-d-comment__content').scrollTop = 100000;
                }
            }, 0);
        },

        prepareDateComment(value) {
            if (!value) {
                return '–';
            }

            if (!moment(value).isValid()) {
                return '–';
            }

            const result = moment(value)
                .format('lll')
                .split(' ')
                .filter((item, i) => {
                    return i != 2;
                })
                .join(' ');


            return result;
        },

        prepareTextComment(value, type) {
            if (!value) {
                return '-';
            }

            if (type === 'INTERNAL' && value.toLowerCase().includes(':') && (value.toLowerCase().includes('snooze') || value.toLowerCase().includes('reminder'))) {
                return `${value} CET`;
            }

            return value;
        },

        prepareDate(value) {
            if (!value) {
                return '–';
            }

            if (!moment(value).isValid()) {
                return '–';
            }

            const day = moment(value).format('lll').split(' ')[0];

            return `${day}-${moment(value).format('DD-YYYY')}`
        },

        prepareTime(value) {
            if (!value) {
                return '';
            }

            if (!moment(value).isValid()) {
                return '–';
            }

            return `${moment(value).format('HH:mm')}`
        },

        prepareFullDate(value) {
            if (!value) {
                return '–';
            }

            if (!moment(value).isValid()) {
                return '–';
            }

            const day = moment(value).format('lll').split(' ')[0];

            return `${day}-${moment(value).format('DD-YYYY HH:mm')}`
        },

        openShareDialog() {
            this.$nextTick(() => {
                this.showShareDialog = true;
            });
        },

        hideShareDialog() {
            this.showShareDialog = false;
        },

        async addDocument(value) {
            this.loadingUpload = true;

            const formData = new FormData();

            value.forEach(item => {
                formData.append('documents[]', item);
            });

            try {
                await axios({
                    method: "post",
                    url: `${this.$apiUrl.consignments.base}/${this.consignment.id}/documents/batch`,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })

                this.$snotify.success(`Success`);

                await this.fetchConsignment();
            } catch(error) {
                this.$snotify.error(error?.response?.data?.errors?.document?.[0] || error?.response?.data?.message || 'Unknown error while uploading file');
            } finally {
                this.loadingUpload = false;
            }
        },

        openDeleteDocumentModal(target) {
            this.documentToDelete = target;
            this.isShowDocumentDeleteModal = true;
        },

        closeDeleteDocumentModal() {
            this.documentToDelete = null;
            this.isShowDocumentDeleteModal = false;
        },

        async deleteDocument(target) {
            this.loadingUpload = true;

            const url = `${this.$apiUrl.consignments.documents}/${target.id}`;

            try {
                await axios.delete(url).then(() => {
                    this.$snotify.success(`Success`);

                    this.fetchConsignment()
                });
            } catch(error) {
                this.$snotify.error(error?.response?.data?.message);
            } finally {
                this.loadingUpload = false;
                this.closeDeleteDocumentModal();
            }
        },

        setTimeLine() {
            const groups = [
                { name: 'System', sort: 1 },
                { name: 'Unfilled', sort: 2 },
                { name: 'Pre transport', sort: 3 },
                { name: 'In transit', sort: 4 },
                { name: 'Reception', sort: 5 },
                { name: 'Delivered', sort: 6 },
                { name: 'Returned', sort: 7 },
                { name: 'Cancelled', sort: 8 },
                { name: 'Ongoing investigation', sort: 9 },
            ];

            let searateEvents = [];

            if (this.consignment.searates?.length) {
                searateEvents = this.consignment.searates.map(searate => {
                    const result = searate.containers.map(container => {
                        return container.events.reduce((accum, event) => {
                            if (!searate.active && !event.actual) {
                                return accum;
                            }

                            event.time = event.date;

                            event.status_from_source = event.status;
                            event.status = event.shipment_status || '';
                            event.group = groups.find(group => group.name.toLowerCase() === event.shipment_status.replace(/_/g, ' ').toLowerCase())?.name;

                            accum.push(event);

                            return accum;
                        }, []);
                    }).flat();

                    return result;
                }).flat();
            }

            const allEvents = [...this.consignment.events, ...searateEvents]
                .filter(item => {
                    return groups.find(group => { return group.name.toLowerCase() == item.group.toLowerCase() });
                })
                .map(item => {
                    return { ...item, sort: groups.find(group => { return group.name.toLowerCase() === item.group.toLowerCase() }).sort};
                });

            const result = [];
            let preparedEvents = [];
            const consignmentEventsSorted = allEvents.sort((a, b) => { return a.sort - b.sort });

            const xceptions = this.consignment.xceptions.filter(item => {
                if (this.adminRoute) {
                    return item;
                }

                if (item.humanized_type === 'Manual' && item.visible_in_client_side) {
                    return item;
                }

                return item.status !== 'NOT_APPLIED' && item.humanized_type !== 'Manual'
            }) || [];

            // Attach xception to an event
            for (let key in consignmentEventsSorted) {
                let event = consignmentEventsSorted[key];

                if (typeof event !== 'object') {
                    continue;
                }

                const eventTime = moment(event.created_at).format('YYYY-MM-DD HH');

                const foundXceptions = xceptions.filter(xc => {
                    const xcTime = moment(xc.created_at).format('YYYY-MM-DD HH');

                    if (xc.event_id || xc.searates_event_id) {
                        return event.id === (xc.event_id || xc.searates_event_id);
                    }

                    return event.name === xc.description && eventTime === xcTime;
                });

                if (foundXceptions.length) {
                    event.xceptions = foundXceptions;
                }

                if (!preparedEvents[event.group]) {
                    preparedEvents[event.group] = [];
                }

                preparedEvents[event.group].push(event);
            }

            // Group events
            for (let group in preparedEvents) {
                if (typeof preparedEvents[group] === 'function') {
                    continue;
                }

                let xceptions = null;
                let events = preparedEvents[group];

                result.push({
                    events: events.sort(allEventsSorter),
                    xceptions,
                })
            }

            this.timeLine = result;

            let search = false;

            this.timeLine.forEach(item => {
                if (!item.events.length) {
                    return;
                }

                item.events.forEach(event => {
                    if (event.xceptions && event.xceptions[event.xceptions.length - 1].status !== 'RESOLVED') {
                        this.lastXception = event.xceptions[event.xceptions.length - 1];

                        search = true;
                    }
                })
            })

            if (!search) {
                this.lastXception = null;
            }
        },

        async getPermissions() {
            const { data: { data } } = await axios.get(this.$apiUrl.whoami);

            this.permissions = data.permissions;
            this.hasEmissionsEnabled = Boolean(data.sitesFilter.find(site => site.applications.indexOf('emissions') !== -1));
        },

        async fetchEmissions() {
            if (!this.accessEmission) {
                return;
            }

            const { data } = await axios.get(`${this.$apiUrl.emissions.base}/` + this.consignment.uuid);

            this.emissions = data.data;
        },
        isShowTime(startDate, endDate) {
            const falsy = {
                start: false,
                end: false
            }

            const startTime = this.prepareTime(startDate);
            const endTime = this.prepareTime(endDate);

            if (!startTime && !endTime) {
                return falsy;
            }

            const isDefaultStart = startTime === '00:00'
            const isDefaultEnd = endTime === '00:00';

            if (isDefaultStart && isDefaultEnd) {
                return falsy;
            }

            if (isDefaultStart && !endTime) {
                return falsy;
            }

            if (!isDefaultStart && (startDate && !endDate)) {
                return {
                    start: true,
                    end: false,
                }
            }

            return {
                start: true,
                end: true,
            }
        }
    },

    async created() {
        await this.fetchConsignment();

        const { data } = await axios.get(`${this.$apiUrl.goodTypeDictionary.search}?carrier_id=${this.consignment.carrier_id}&carrier_name=${this.consignment.carrier_name}`);

        this.goodTypeDictionary = data || [];

        if (this.user) {
            await Promise.all([
                this.setDictionary(),
                this.getPermissions(),
            ]);

            if (!this.hasEmissionsEnabled) {
                return;
            }

            try {
                await this.fetchEmissions();
            } catch(_) {
                this.emissions = {};
            }
        }
    },

    watch: {
        editGoodsDescription(value) {
            if (!value) {
                this.goodsDescription = this.consignment.goods_description;
            }
        },
    },
};
</script>

<style lang="css" scoped>
.word-break {
    word-break: break-word;
}
</style>

<style lang="scss">
.sh-d {
    &__border {
        border-bottom: 2px solid var(--color-icon-background);
    }
    &__grid {
        display: grid;
        grid-template-areas:
            'from to'
            'pickup delivery'
            'sender receiver'
            'timeline-1 timeline-2'
            'bottom-1 bottom-2'
            'toggle toggle';
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;

        &-header {
            padding: 8px 0;

            &--blue {
                background: var(--color-blue-2);
                color: var(--color-blue-1);
            }
            &--green {
                background: var(--color-green-2);
                color: var(--color-green-1);
            }
        }
    }

    &__from {
        grid-area: from;
    }

    &__to {
        grid-area: to;
    }

    &__pickup {
        grid-area: pickup;
    }

    &__delivery {
        grid-area: delivery;
    }

    &__sender {
        grid-area: sender;
    }

    &__receiver {
        grid-area: receiver;
    }

    &__timeline-1 {
        grid-area: timeline-1;
    }

    &__timeline-2 {
        grid-area: timeline-2;
    }

    &__bottom-1 {
        grid-area: bottom-1;
    }

    &__bottom-2 {
        grid-area: bottom-2;
    }

    &__toggle {
        grid-area: toggle;

        button {
            margin: 0 auto;
        }
    }

    &__card--empty {
        .sh-d-progress__item {
            position: relative;

            .sh-d-circle__circle {
                background-color: white;
            }

            &:last-child {
                .sh-d-circle {
                    height: auto;
                }

                .sh-d-circle__line.sh-d-circle--empty  {
                    display: none;
                }
            }
        }
    }
}

.sh-d-circle--empty {
    span {
        background-color: #cfcfcf;
    }
}
.sh-d-circle--empty {
    border-color: #cfcfcf;
}

.sh-d__emissions > .modal {
    overflow-y: auto !important;
}

.sh-d__goods-description {
    max-height: 180px;
}

.sh-detail__right-subtitle {
    max-width: 40%;
    min-width: 100px;
    white-space: nowrap;
}

.sh-d-additional__item:not(.sh-d-additional__item:last-child) {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #E6E6E6;
}

.sh-d-additional__head {
    &.clickable {
        cursor: pointer;

        svg {
            path {
                stroke: var(--brand-color);
            }
        }

        .text-header-secondary {
            color: var(--brand-color);
        }
    }
}

.sh-d-message {
    &.question {
        background: var(--color-yellow-1);

        .sh-d-comment__type {
            color: var(--color-yellow-2)  !important;
        }
    }
    &.external {
        background: var(--color-green-2);

        .sh-d-comment__type {
            color: var(--color-green-1) !important;
        }
    }
    &.note {
        .sh-d-comment__type {
            background: var(--color-blue-2);
        }
    }
    &.partner {
        background: var(--color-blue-2) !important;

        &.note {
            .sh-d-comment__type {
                outline: 1px solid #fff;
            }
        }
    }
}
.modal-add-address .modal__body {
    overflow: inherit;
}
</style>
